<template>
  <div id="resource">
    <div class="wrapper">
      <div class="top">
        <Form
          ref="formInline"
          :model="formInline"
          label-position="right"
          :label-width="80"
          inline
        >
          <span
            style="
              line-height: 32px;
              font-size: 16px;
              color: #fff;
              margin-right: 10px;
            "
            >模型类型 :
          </span>
          <FormItem prop="user" :label-width="0">
            <Select v-model="SelectType" style="width: 150px">
              <Option value="0">全部</Option>
              <Option value="2">正射</Option>
              <Option value="1">模型</Option>
            </Select>
          </FormItem>
          <FormItem prop="password" :label-width="50">
            <Input
              v-model="taskName"
              clearable
              @on-clear="SelectTask"
              placeholder="请输入模型名称"
            >
            </Input>
          </FormItem>
          <!-- <FormItem :label-width="10">
            <Button type="primary"
                    @click="SelectTask"
                    style="margin-left: 10px">搜索</Button>
          </FormItem> -->
        </Form>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="item1">
            <span class="name">云端存储空间</span>
            <span class="stockpile"
              >已使用 {{ this.storageSpace.use_space || 0 }} MB/{{
                this.storageSpace.storage_space || 0
              }}
              MB</span
            >
          </div>
          <div class="item2">
            <Progress
              :percent="storageSpaceRatio"
              :stroke-color="strokeColor"
              style="width: 400px"
            />
            <!--<a href="javascript:void(0)" @click="">增加存储空间>></a>-->
          </div>
          <!--<div class="item3">-->
          <!--<div class="circle Box1">-->
          <!--<div></div>-->
          <!--<span>正射</span>-->
          <!--</div>-->
          <!--<div class="circle Box2">-->
          <!--<div></div>-->
          <!--<span>三维</span>-->
          <!--</div>-->
          <!--</div>-->
        </div>
        <div class="right">
          <Button
            type="primary"
            to="/resourceIndex/Terra"
            style="margin-right: 15px"
            v-show="TerraShow"
            >云计算</Button
          >

          <Button type="primary" @click="upload(1)" style="margin-right: 10px"
            >上传模型</Button
          >
          <Button type="primary" @click="upload(2)">上传正射</Button>
        </div>
      </div>
    </div>
    <div class="conent">
      <Table :loading="loading" :columns="columns" :data="modelData"></Table>
      <div style="margin-top: 15px">
        <Page
          :total="total"
          show-total
          v-if="total > limit"
          :page-size="limit"
          style="text-align: center"
          :current="parseInt(page)"
          @on-change="changePage"
        />
      </div>
    </div>

    <!--弹框区-->
    <!--删除模型-->
    <Modal v-model="deleteModelShou" width="360">
      <p slot="header" style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>删除此模型</span>
      </p>
      <div style="text-align: center">
        <p>
          是否确认删除
          <span style="color: red">{{
            this.deleteModelData.mapname || ''
          }}</span>
          ?<br />一经删除无法恢复.
        </p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="remove">确认删除</Button>
      </div>
    </Modal>
    <!--添加模型-->
    <Modal
      v-model="addModelShou"
      class-name="addModel-modal"
      footer-hide
      @on-cancel="closeAddModel"
      :mask-closable="false"
    >
      <p slot="header" style="text-align: center">
        <span>添加资源</span>
      </p>
      <div style="padding: 10px 30px">
        <Form
          ref="addModel"
          :model="addModel"
          :rules="addModel_ruleInline"
          label-position="top"
        >
          <FormItem label="上传类型 :">
            <div class="action-wrapper">
              <div
                class="action"
                :class="addType === 'orthographic' ? 'selected' : ''"
                @click="chooseType('orthographic', '正射拼图')"
              >
                <div class="img">
                  <img
                    class="orthographic"
                    src="@/assets/img/Icon/orthographic_big.png"
                    alt=""
                  />
                </div>
                <span>正射地图</span>
              </div>
              <div class="Separator"></div>
              <div
                class="action"
                :class="addType === 'Model' ? 'selected' : ''"
                @click="chooseType('Model', '三维模型')"
              >
                <div class="img">
                  <img
                    class="Model"
                    src="@/assets/img/Icon/model_icon_big.png"
                    alt=""
                  />
                </div>
                <span>三维模型</span>
              </div>
            </div>
          </FormItem>
          <!--正射信息区-->
          <div v-if="orthographicContent">
            <FormItem label="名称 :">
              <Input
                v-model="orthographicUpLoadData.mapname"
                placeholder="必填"
              ></Input>
            </FormItem>
            <Row>
              <Col span="12">
                <FormItem label="所属任务(可选) :">
                  <Cascader
                    :data="taskData"
                    v-model="value1"
                    style="width: 200px"
                  ></Cascader>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="拍摄日期 :">
                  <DatePicker
                    type="date"
                    :editable="false"
                    v-model="MapDate"
                    placeholder="必选"
                    style="width: 200px"
                  ></DatePicker>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="12">
                <FormItem label="照片数量 :">
                  <Input
                    v-model="orthographicUpLoadData.mapcount"
                    placeholder="必填"
                    style="width: 95%"
                  ></Input>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="地图面积 /m² : ">
                  <InputNumber
                    v-model="orthographicUpLoadData.maparea"
                    :step="0.5"
                    placeholder="必填"
                    style="width: 95%"
                  ></InputNumber>
                </FormItem>
              </Col>
            </Row>
            <FormItem label="上传ZIP文件" prop="device_name">
              <div v-if="clearUp" id="upload-container" @click="start()">
                <span>点击选择文件</span>
              </div>

              <p v-else style="text-align: center; margin-top: 10px">
                正在清除缓存文件,请稍后...
              </p>
              <Upload
                ref="OrUpLoad"
                name="fileUpload"
                :before-upload="onBefore"
                :show-upload-list="true"
                :format="['zip', 'ZIP']"
                :on-format-error="handleFormatError"
                :on-success="OrthophotoSuccess"
                :on-error="OrthophotoError"
                :disabled="uploadDisabled"
                :on-progress="onProgress"
                type="drag"
                :data="upLoadFileData"
                :action="CommonUploadURL"
                style="display: inline-block; width: 100%"
              >
                <div style="padding: 20px 0; width: 100%">
                  <Icon
                    type="ios-cloud-upload"
                    size="52"
                    style="color: #3399ff"
                  ></Icon>
                  <p style="font-size: 20px">点击或将文件拖动到这里进行上传</p>
                  <p>(必须填写上方信息才可进行上传)</p>
                </div>
              </Upload>
            </FormItem>
          </div>
          <!--模型信息区-->

          <div v-if="modelContent">
            <FormItem label="名称 :" prop="team_name">
              <Input
                v-model="UpLoad3DData.modelname"
                placeholder="必填"
              ></Input>
            </FormItem>
            <Row>
              <Col span="12">
                <FormItem label="所属任务(可选) :">
                  <Cascader
                    :data="taskData"
                    v-model="value2"
                    style="width: 200px"
                  ></Cascader>
                </FormItem>
              </Col>
              <Col span="12">
                <FormItem label="地图面积 /m² :">
                  <InputNumber
                    v-model="UpLoad3DData.modelarea"
                    :step="0.5"
                    placeholder="必填"
                    style="width: 95%"
                  ></InputNumber>
                </FormItem>
              </Col>
            </Row>
            <FormItem label="上传ZIP文件" prop="device_name">
              <Upload
                ref="OrUpLoad"
                name="fileUpload"
                :before-upload="onBefore"
                :show-upload-list="true"
                :format="['zip', 'ZIP']"
                :on-format-error="handleFormatError"
                :on-error="OrthophotoError"
                :on-success="OrthophotoSuccess"
                :disabled="uploadDisabled"
                :on-progress="onProgress"
                type="drag"
                :headers="{ token: token }"
                :data="upLoadFileData"
                :action="CommonUploadURL"
                style="display: inline-block; width: 100%"
              >
                <div style="padding: 20px 0; width: 100%">
                  <Icon
                    type="ios-cloud-upload"
                    size="52"
                    style="color: #3399ff"
                  ></Icon>
                  <p style="font-size: 20px">点击或将文件拖动到这里进行上传</p>
                  <p>(必须填写上方信息才可进行上传)</p>
                </div>
              </Upload>
            </FormItem>
          </div>
        </Form>
      </div>
      <!--<div style="text-align:center" slot="footer">-->
      <!--<Button-->
      <!--type="info"-->
      <!--@click="AddModel_ok"-->
      <!--style="width: 200px;letter-spacing:8px;"-->
      <!--&gt;确认</Button>-->
      <!--</div>-->
    </Modal>
    <div id="picker" style="display: none"></div>
    <!--编辑-->
    <Modal
      v-model="redactModelShou"
      class-name="addModel-modal"
      @on-cancel="closeredactModel"
      :mask-closable="false"
    >
      <p slot="header" style="text-align: center">
        <span>模型重命名</span>
      </p>
      <div style="padding: 10px 30px">
        <Form ref="redactModel" :model="redactModel" label-position="top">
          <FormItem label="修改模型名称" prop="name">
            <Input v-model="redactModel.name"></Input>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="redactModel_ok"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!--查看-->
    <Modal
      v-model="lookModel"
      fullscreen
      footer-hide
      class-name="Look-center-modal"
      @on-cancel="closeLookModel"
    >
      <p slot="header">
        <span>{{ lookModelData.mapname }}</span>
      </p>
      <div style="width: 100%; height: 100%">
        <div
          class="OrthophotoContent"
          id="OrthophotoContent"
          v-if="lookModelData.type == 2"
        ></div>
        <div
          class="ModelContent"
          id="ModelContent"
          v-if="lookModelData.type == 1"
        ></div>
      </div>
    </Modal>
  </div>
</template>

<script>
import model_icon from '@/assets/img/Icon/model_icon.png'
import orthographic from '@/assets/img/Icon/orthographic.png'
import Api from '@/utils/api.js'
import { UploadFile, AddModel, AddYinshe } from '@/utils/javaApi.js'

export default {
  name: 'index',
  data() {
    return {
      limit: 10,
      teamID: -1, // 团队id
      userType: '',
      userInfo: {},
      Project1info: {},
      storageSpace: {},
      storageSpaceRatio: 0,
      strokeColor: ['#108ee9', '#87d068'],
      formInline: {
        type: '0',
        taskName: '',
      },
      columns: [
        // {
        //   title: '#',
        //   key: 'id',
        //   width: 80,
        //   align: 'center',
        // },
        {
          title: '模型类型',
          key: 'modelType',
          //   width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('img', {
                attrs: {
                  src: params.row.modelType,
                  title: '查看详情',
                },
                style: {
                  width: '35px',
                  height: '35px',
                  textAlign: 'center',
                  display: 'inline-block',
                  marginTop: '4px',
                },
              }),
            ])
          },
        },
        {
          title: '模型名称',
          key: 'mapname',
          align: 'center',
        },
        {
          title: '模型日期',
          key: 'create_time',
          align: 'center',
        },
        // {
        //   title: '模型面积 / m²',
        //   key: 'maparea',
        //   align: 'center',
        // },
        // {
        //   title: '所属任务',
        //   //   key: 'bf_id',
        //   align: 'center',
        //   render: (h, params) => {
        //     let mission_name = '未绑定任务';
        //     if (this.taskData.length > 0) {
        //       mission_name = this.taskData.filter(
        //         (el) => el.value === params.row.bf_id
        //       );
        //       if (mission_name.length > 0) {
        //         mission_name = mission_name[0].label;
        //       } else {
        //         mission_name = '未绑定任务';
        //       }
        //     }

        //     return h('span', mission_name);
        //   },
        // },
        {
          title: '文件大小/MB',
          key: 'file_size',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 250,
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'info',
                    size: 'small',
                  },
                  style: {
                    marginRight: '10px',
                  },
                  on: {
                    click: () => {
                      // params.row
                      this.lookModelData = params.row
                      console.log(params.row)
                      setTimeout(() => {
                        this.lookModel = true

                        if (params.row.mode_type == 2) {
                          this.lookOrthophotoFn(params.row)
                        } else {
                          this.lookModelFn(params.row)
                        }
                      }, 300)
                    },
                  },
                },
                '查看'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '10px',
                  },
                  on: {
                    click: () => {
                      this.redactModelData = params.row
                      if (params.row.mode_type == 2) {
                        this.redactModel.name = params.row.mapname
                        this.redactModel.tid = params.row.team_id
                      } else if (params.row.mode_type == 1) {
                        this.redactModel.name = params.row.mapname
                        this.redactModel.tid = params.row.team_id
                      } else {
                        this.$Message.error('此模型无法编辑')
                        return
                      }

                      this.redactModelShou = true
                    },
                  },
                },
                '重命名'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.deleteModelShou = true
                      this.deleteModelData = params.row
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      modelData: [],
      loading: false,
      SelectType: 0,
      taskName: '',
      deleteModelShou: false,
      deleteModelData: {},
      addModelShou: false,
      addModel: {},
      addModel_ruleInline: {},
      addType: '',
      value1: [],
      value2: [],
      taskData: [],
      //上传地址
      UploadURL: '',
      CommonUploadURL: '',
      // 上传的数据
      //正射
      orthographicUpLoadData: {
        mapname: '',
        mapdate: '',
        mapcount: '', //照片数量
        maparea: 0, //地图面积
        // pm_first_id: "",
        // pm_second_id: "",
        team_id: '',
        bf_id: '', //任务id
      },
      MapDate: '',
      //模型
      UpLoad3DData: {
        modelname: '',
        modelarea: 0, //地图面积
        // pm_first_id: "",
        // pm_second_id: "",
        team_id: '',
        bf_id: '', //任务id
      },
      UploadDisabled1: true,
      UploadDisabled2: true,
      orthographicContent: false,
      modelContent: false,
      // 编辑模型
      redactModelShou: false,
      redactModelData: {},
      redactModel: {
        name: '',
        tid: '',
      },
      lookModel: false,
      lookModelData: {},
      map: null,
      GetMapDetail: null,
      imageLayerList: [],
      googleLayer: null,
      TerraShow: false,
      aaa: [], //可以看到云计算的账号
      showPage: false,
      total: 0,
      page: 1,
      upLoadFileData: {
        dir: '',
      },
      file_size: 0,
      token: '',
      uploadDisabled: false,
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    this.token = sessionStorage.getItem('token')
    this.CommonUploadURL = UploadFile()
    this.upLoadFileData.token = this.token
    this.teamID = this.userInfo.team_id
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')

    // this.teamID = this.userInfo.team_id;
    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))
    this.loading = true
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page')
    }
    if (sessionStorage.getItem('team_id') === null) {
      this.teamID = this.userInfo.team_id
      this.SelectTask()
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'))
      this.SelectTask()
    }
    // this.getTaskList();
    this.orthographicUpLoadData.team_id = this.userInfo.team_id
    this.UpLoad3DData.team_id = this.userInfo.team_id
    this.TerraShow = this.aaa.indexOf(this.userInfo.pm_id) == -1 ? false : true
    if (sessionStorage.getItem('page')) {
      this.page = sessionStorage.getItem('page')
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value
      this.changePage(1)
    })
    this.$_bus.$off('reLoad').$on('reLoad', () => {
      this.SelectTask()
    })
  },
  methods: {
    // 上传事件
    upload(type) {
      switch (type) {
        case 1:
          this.$store.commit('changeFileType', 1)
          this.$_bus.$emit('addModalshow')
          break
        case 2:
          this.$store.commit('changeFileType', 2)
          this.$_bus.$emit('addModalshow')
          break
      }
    },
    onBefore(file) {
      // 开始上传前,选中文件后执行
      if (this.addType == 'orthographic') {
        if (
          !this.orthographicUpLoadData.mapname ||
          !this.orthographicUpLoadData.mapdate ||
          !this.orthographicUpLoadData.mapcount ||
          !this.orthographicUpLoadData.maparea
        ) {
          this.$Message.warning('请先填写正射信息')
          this.file_size = 0
          return false
        }
      }
      if (this.addType == 'Model') {
        if (!this.UpLoad3DData.modelname || !this.UpLoad3DData.modelarea) {
          this.$Message.warning('请先填写模型信息')
          this.file_size = 0
          return false
        }
      }
      if (
        this.storageSpace.storage_space * 1048576 -
          this.storageSpace.use_space * 1048576 <
        file.size
      ) {
        this.$Message.warning('团队云端存储空间不足')
        return false
      }
      this.file_size = file.size
    },
    // 开始文件上传,禁用上传组件
    onProgress() {
      this.uploadDisabled = true
    },
    changePage(ind) {
      this.page = ind
      sessionStorage.setItem('page', ind)
      this.SelectTask()
    },
    // 获取任务列表
    getTaskList() {
      this.$post(Api.getSecondPmFigure()).then((res) => {
        this.taskData = []
        if (res.data == undefined || res.data == []) {
          return
        }
        if (res.code == 1) {
          this.taskData = res.data
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //搜索任务名称
    SelectTask() {
      this.loading = true
      this.showPage = false
      this.$post(Api.loadAllResource(), {
        team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
        type: this.SelectType,
        bf_name: this.taskName,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        console.log('列表数据<<<<<<<<<<<<<<<<<<<<', res)
        this.loading = false
        this.modelData = []
        this.total = res.data.total
        if (res.data == undefined || res.data.data[0].dataListlength == 0) {
          return
        }
        if (res.code == 1) {
          this.storageSpace = res.data.data[0].pm_space
          var Ratio = Math.ceil(
            (this.storageSpace.use_space / this.storageSpace.storage_space) *
              100
          )
          this.$store.commit(
            'changeResidue',
            parseInt(
              this.storageSpace.storage_space - this.storageSpace.use_space
            ) *
              1024 *
              1024
          )
          if (this.total > 5) {
            setTimeout(() => {
              this.showPage = true
            }, 100)
          }
          console.log(Ratio, this.storageSpace)
          Ratio >= 100
            ? (this.storageSpaceRatio = 100)
            : (this.storageSpaceRatio = Ratio)
          this.modelData = res.data.data[0].data_list

          this.modelData.map((el) => {
            el.file_size = (el.file_size / 1024).toFixed(2)
            if (el.mode_type == 2) {
              el.modelType = orthographic // 图片地址
            } else {
              el.modelType = model_icon // 图片地址
            }
          })

          //   res.data.data[0].data_list.forEach((item) => {
          //     item.file_size = (item.file_size / 1024 / 1024).toFixed(2);
          //     // 1 模型 2 正射
          //     if (item.mode_type == 2) {
          //       item.modelType = orthographic; // 图片地址
          //     } else {
          //       item.modelType = model_icon; // 图片地址
          //     }
          //     this.modelData.push(item);
          //   });
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //删除模型或正射
    remove() {
      this.deleteModelShou = false
      let row = this.deleteModelData
      if (row.mode_type == 2) {
        //正射
        this.$post(Api.deleteOrthophoto(), {
          id: row.id,
          map_id: row.kmlmsg[0].kml_id,
        }).then((res) => {
          this.deleteModelData = {}
          if (res.code == 1) {
            this.$Message.info(res.msg_customer)
            this.SelectTask()
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      } else {
        //模型
        this.$post(Api.deleteModel(), {
          model_id: row.id,
        }).then((res) => {
          this.deleteModelData = {}
          if (res.code == 1) {
            this.$Message.info(res.msg_customer)
            this.SelectTask()
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      }
    },
    chooseType(Type, dir) {
      this.addType = Type
      this.upLoadFileData.dir = dir
    },
    // 上传
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择zip压缩包.',
      })
    },

    OrthophotoSuccess(res) {
      let url, params
      if (this.addType == 'orthographic') {
        url = AddYinshe()
        params = {
          mapname: this.orthographicUpLoadData.mapname,
          mapdate: this.orthographicUpLoadData.mapdate,
          mapcount: this.orthographicUpLoadData.mapcount,
          maparea: this.orthographicUpLoadData.maparea,
          team_id: this.userInfo.team_id,
          bf_id: this.orthographicUpLoadData.bf_id,
          file: res.data.decom_path,
          file_size: parseInt(this.file_size / 1024),
          mode_type: 2, // 地图
        }
      }

      if (this.addType == 'Model') {
        console.log('mx')
        url = AddModel()
        params = {
          modelname: this.UpLoad3DData.modelname,
          modelarea: this.UpLoad3DData.modelarea,
          team_id: this.userInfo.team_id,
          bf_id: this.UpLoad3DData.bf_id,
          file_size: parseInt(this.file_size / 1024),
          mode_type: 1, // 模型
          file: res.data.decom_path,
        }
      }

      this.$post(url, params).then((res) => {
        if (res.code === 1) {
          console.log('上传成功了')
          this.$Message.success('文件上传成功')
          this.teamID = this.userInfo.team_id
          this.addModelShou = false
          // 清空数据
        } else {
          this.closeAddModel()
          this.$Message.error('文件有错，添加失败！')
        }
        this.closeAddModel()
        this.SelectTask()
      })
      this.uploadDisabled = false
    },
    OrthophotoError(error) {
      this.uploadDisabled = false
      this.$Message.error('上传失败')
      console.log(error)
    },
    // 关闭添加框,清空数据
    closeAddModel() {
      //上传地址
      this.UploadURL = ''
      //上传的数据
      //正射
      this.orthographicUpLoadData = {
        mapname: '',
        mapdate: '',
        mapcount: '', //照片数量
        maparea: 0, //地图面积
        // team_id: this.userType == 0 ? this.Project1info.pm_id : this.teamID,
        // pm_second_id: "",
        bf_id: '', //任务id
      }
      this.MapDate = ''
      this.value1 = []
      this.value2 = []
      //模型
      this.UpLoad3DData = {
        modelname: '',
        modelarea: '', //地图面积
        // team_id: this.userType == 0 ? this.Project1info.pm_id : this.teamID,
        // pm_second_id: "",
        bf_id: '', //任务id
      }
      this.addType = ''
    },
    // 编辑模型 模型重命名
    redactModel_ok() {
      let url, params

      if (this.redactModelData.mode_type == 2) {
        // 正射
        url = Api.updateOrthophoto()
        params = {
          ortho_id: this.redactModelData.id,
          team_id: this.redactModel.tid,
          bf_id: this.redactModelData.bf_id,
          mapname: this.redactModel.name,
        }
      } else if (this.redactModelData.mode_type == 1) {
        // 模型
        url = Api.updateModel()
        params = {
          model_id: this.redactModelData.id,
          team_id: this.redactModel.tid,
          bf_id: this.redactModelData.bf_id,
          modelname: this.redactModel.name,
        }
      }

      this.$post(url, params).then((res) => {
        if (res.code == 1) {
          // 编辑成功
          this.$Message.success('编辑成功')
          this.redactModelShou = false
          this.SelectTask()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //关闭编辑窗口
    closeredactModel() {
      this.redactModelData = {}
      this.redactModel.name = ''
      this.redactModel.tid = ''
    },
    //预览模型
    lookModelFn(row) {
      sessionStorage.setItem('ModelShouData', JSON.stringify(row))
      this.$router.push({
        path: '/modelShou',
      })
    },
    //预览正射
    lookOrthophotoFn(row) {
      this.$router.push({
        path: '/orthographicShou',
        query: { id: row.kmlmsg[0].kml_id },
      })
    },
    //关闭预览窗口
    closeLookModel() {},
  },
  watch: {
    taskName(val) {
      this.page = 1
      this.SelectTask()
    },
    SelectType(val) {
      this.page = 1
      this.SelectTask()
    },
    addType(val) {
      if (val == 'orthographic') {
        this.orthographicContent = true
        this.modelContent = false
      } else if (val == 'Model') {
        this.orthographicContent = false
        this.modelContent = true
      } else {
        this.orthographicContent = false
        this.modelContent = false
        this.UploadURL = ''
      }
    },
    value1(val) {
      this.orthographicUpLoadData.bf_id =
        val.length > 0 ? val[val.length - 1] : ''
    },
    value2(val) {
      this.UpLoad3DData.bf_id = val.length > 0 ? val[val.length - 1] : ''
    },
    MapDate(val) {
      this.orthographicUpLoadData.mapdate =
        this.$moment(val).format('YYYY-MM-DD')
    },
    orthographicUpLoadData: {
      deep: true,
      handler: function (val) {
        if (
          val.mapname !== '' &&
          val.mapdate !== '' &&
          val.mapcount !== '' &&
          val.maparea !== 0 &&
          val.bf_id !== ''
        ) {
          this.UploadDisabled1 = false
        } else {
          this.UploadDisabled1 = true
        }
      },
    },
    UpLoad3DData: {
      deep: true,
      handler: function (val) {
        if (val.modelname !== '' && val.modelarea !== '' && val.bf_id !== '') {
          this.UploadDisabled2 = false
        } else {
          this.UploadDisabled2 = true
        }
      },
    },
  },
  beforeDestroy() {
    this.$_bus.$off('change_Team')
    this.$_bus.$off('reLoad')
  },
}
</script>

<style scoped lang="scss">
#resource {
  width: 100%;
  height: 100%;
  position: relative;
  padding: pcRem(24) pcRem(20);
  background-color: #313131;
  .wrapper {
    width: 100%;
    height: pcRem(200);
    margin-bottom: pcRem(14);
    padding: pcRem(30) pcRem(40);
    background-color: #313131;
    box-shadow: 0px 3px 6px #000;
    border-radius: 4px;
    .top {
      height: pcRem(40);
      margin-bottom: pcRem(20);
    }
    .bottom {
      display: flex;
      height: pcRem(80);
      justify-content: space-between;
      align-items: center;

      .left {
        .item1 {
          width: 340px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-size: 16px;
            color: #fff;
          }
          .stockpile {
            font-size: 12px;
            color: #999;
          }
        }
        .item3 {
          display: flex;
          align-items: center;
          .circle {
            display: flex;
            align-items: center;
          }
          .Box1 {
            > div {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #ecd500;
              margin-right: 10px;
            }
            > span {
              margin-right: 30px;
            }
          }
          .Box2 {
            > div {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background-color: #ff9500;
              margin-right: 10px;
            }
            > span {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }
  .conent {
    width: 1808px;
    margin: 24px 20px;
    padding: 24px 20px;
    position: absolute;
    background-color: #313131;
    box-shadow: 0 3px 6px #000;
    border-radius: 4px;
    // top: 214px;
    // bottom: 0;
    left: 0;
    overflow: auto;
    /deep/ .ivu-table th {
      background: #333;
      color: #fff;
    }
    /deep/ .ivu-table {
      background: unset;
    }
    /deep/ .ivu-table td {
      background: #1e1e1e;
      color: #fff;
    }
    /deep/ .ivu-table td,
    .ivu-table th {
      border-bottom: 1px solid #000;
    }
    /deep/ .ivu-table th {
      border-bottom: 1px solid #000;
    }
  }
}
/deep/.addModel-modal {
  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .action {
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      opacity: 0.6;
      .img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      span {
        color: #666666;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .selected {
      opacity: 1;
    }
    .Separator {
      width: 1px;
      height: 120px;
      background-color: #d6d6d6;
    }
  }
}
/deep/.Look-center-modal {
  .ivu-modal-body {
    padding: 0;
  }
  .OrthophotoContent {
    width: 100%;
    height: 100%;
    background-color: #ee2f3f;
  }
  .ModelContent {
    width: 100%;
    height: 100%;
    background-color: #b4b4b4;
  }
}
</style>
